<template>
  <div class="page page--order-confirmed order-confirmed">
    <div class="page__block">
      <h2>{{ $t('orderconfirm.title') }}</h2>

      <p>{{ $t('orderconfirm.text1') }} {{ orderId }} {{ $t('orderconfirm.text2') }}</p>

      <p v-if="CN">{{ $t('booking.name') }}: {{ CN }}</p>
      <p v-if="payId">{{ $t('orderconfirm.ref') }}: {{ payId }}</p>
      <p>{{ $t('backto') }}
        <router-link v-if="travelagent" :to="{name:'Home', query:{TALIC:talic,UID:uid,TAEMAIL:taEmail,TANAME:taName,AGENT:travelagent}}">Home</router-link>
        <router-link v-else to="/">Home</router-link>
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: "OrderConfirmed",
  data() {
    return {
      orderId: this.$route.query.orderID,
      CN: this.$route.query.CN,
      payId: this.$route.query.PAYID,

      talic: this.$route.query.TALIC,
      uid: this.$route.query.UID,
      taEmail: this.$route.query.TAEMAIL,
      taName:this.$route.query.TANAME,
      travelagent: this.$route.query.AGENT
    };
  },

  mounted() {
    window.scrollTo(0, 0);
  },

  created() {
    if (!this.orderId) {
      this.$router.push('/not-found');
    }
  }
}
</script>